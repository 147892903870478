import { useCallback, useMemo } from 'react'
import useSWR from 'swr'
import { buildCacheKey } from '../../utils/cache'
import { createBookReview, deleteBookReview, getBookReviews, likeBookReview, updateBookReview } from './api'

export const useBookReviews = ({
  bookId,
  userId,
  page = 0,
  perPage = 10
}: {
  bookId: number
  userId?: number | null
  page?: number
  perPage?: number
}) => {
  const cacheKey = buildCacheKey('useBookReviews', { bookId, page, perPage })

  const {
    data: reviews,
    isLoading,
    mutate: dispatchReviewsUpdate
  } = useSWR(cacheKey, () => getBookReviews({ bookId, page, perPage }))

  const userReview = useMemo(() => reviews?.array.find((review) => review.user.id === userId), [reviews, userId])

  const upsertReview = useCallback(
    async ({ review }: { review: string }) => {
      if (!userId) return

      await dispatchReviewsUpdate(
        async (prevReviews) => {
          if (userReview) {
            await updateBookReview({ bookId, reviewId: userReview.id, review })
          } else {
            await createBookReview({ bookId, review })
          }
          return prevReviews
        },
        { rollbackOnError: true }
      )
    },
    [dispatchReviewsUpdate, userReview]
  )

  const deleteReview = useCallback(
    async ({ reviewId }: { reviewId: number }) => {
      if (!userId) return

      await dispatchReviewsUpdate(
        async (prevReviews) => {
          await deleteBookReview({ bookId, reviewId })
          return prevReviews
        },
        { rollbackOnError: true }
      )
    },
    [dispatchReviewsUpdate, userReview]
  )

  const toggleLikeReview = useCallback(
    async ({ reviewId }: { reviewId: number }) => {
      if (!userId) return

      const optimisticData = reviews
        ? {
            ...reviews,
            array: reviews.array.map((r) => (r.id === reviewId ? { ...r, userLiked: !r.userLiked } : r))
          }
        : undefined

      await dispatchReviewsUpdate(
        async () => {
          await likeBookReview({ bookId, reviewId })
          return optimisticData
        },
        {
          rollbackOnError: true,
          optimisticData
        }
      )
    },
    [dispatchReviewsUpdate, userReview, reviews]
  )

  return {
    data: reviews?.array,
    pagination: reviews?.pagination,
    isLoading,
    upsertReview,
    deleteReview,
    userReview,
    toggleLikeReview
  }
}
