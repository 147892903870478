import { authenticatedApiInstance } from '../client'
import { MeiliSearchResponse } from '../types.api'

export type MeiliSearchParams = {
  q: string
  offset?: number
  limit?: number
  hitsPerPage?: number
  page?: number
  filter?: (string | string[])[] | null
  facets?: string[] | null
  attributesToRetrieve?: string[]
  attributesToCrop?: string[] | null
  cropLength?: number
  cropMarker?: string
  attributesToHighlight?: string[] | null
  highlightPreTag?: string
  highlightPostTag?: string
  showMatchesPosition?: boolean
  sort?: string[] | null
  matchingStrategy?: string
  showRankingScore?: boolean
  showRankingScoreDetails?: boolean
  rankingScoreThreshold?: number | null
  attributesToSearchOn?: string[]
  hybrid?: object | null
  vector?: number[] | null
  retrieveVectors?: boolean
  locales?: string[] | null
}

export const getHits = async ({ params }: { params: MeiliSearchParams }): Promise<MeiliSearchResponse> =>
  authenticatedApiInstance()
    .post(`/search`, params)
    .then((res) => res.data)
