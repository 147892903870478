import axios from 'axios'

import { getApiTenant } from '../utils/api'
import { getConfigVariable } from '../utils/config'
import { SearchIndexMapping } from './constants.api'

const SEARCH_API_URL = getConfigVariable('SEARCH_API_URL')
const SEARCH_API_KEY = getConfigVariable('SEARCH_API_KEY')

export const authenticatedApiInstance = () => {
  const tenant = getApiTenant()
  const env = getConfigVariable('NODE_ENV')
  const indexName = SearchIndexMapping[tenant]
  return axios.create({
    baseURL: `${SEARCH_API_URL}/indexes/${indexName}`,
    headers: {
      Authorization: `Bearer ${SEARCH_API_KEY}`,
      'Content-Type': 'application/json'
    }
  })
}
