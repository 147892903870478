import useSWR from 'swr'
import type { BookDto } from '@hermes/domains'
import { getBookById } from './api'
import { buildCacheKey } from '../../utils/cache'

export const useBookDetails = ({ bookId, initialData }: { bookId: number | string; initialData?: BookDto }) => {
  const cacheKey = buildCacheKey('useBookDetails', { bookId })

  const { data, isLoading } = useSWR(cacheKey, () => getBookById({ bookId }), {
    fallbackData: initialData,
    keepPreviousData: true
  })

  return {
    data,
    isLoading
  }
}
