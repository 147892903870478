import { getActiveTenant } from '../setActiveTenant'
import { getActiveUser } from '../setActiveUser'
import { Region } from './region'

export const getApiTenant = () => {
  const tenant = getActiveTenant()

  const DEFAULT_TENANT = Region.NORWAY
  const tenants = new Set(Object.values(Region))

  if (!tenant) return DEFAULT_TENANT

  return tenants.has(tenant) ? tenant : DEFAULT_TENANT
}

export const getAuthToken = () => {
  const user = getActiveUser()
  if (!user) throw new Error('No user is active')
  return user.token
}
