import React, { ElementType } from 'react'
import MuiTypography, { TypographyProps as MuiTypographyProps } from '@mui/material/Typography'

interface TypographyProps extends MuiTypographyProps {
  component?: ElementType<any>
}

const Typography = ({ variant = 'body1', component = 'p', ...props }: TypographyProps) => (
  <MuiTypography variant={variant} component={component} {...props} />
)

export { Typography, type TypographyProps }
