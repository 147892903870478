import { Region } from '../utils/region'

export enum SortByAttribute {
  RELEVANCE = '',
  RATING = 'ratingAvg:desc',
  POPULARITY = 'popularity.popularityScore360d:desc'
}

export enum SearchIndex {
  SWEDEN = 'books_sweden',
  NORWAY = 'books_norway'
}

export const SearchIndexMapping: Record<Region, SearchIndex> = {
  [Region.SWEDEN]: SearchIndex.SWEDEN,
  [Region.NORWAY]: SearchIndex.NORWAY
}
