import type { UserListInterface } from '@bookis/legacy-types'
import { authenticatedApiInstance } from '../client'
import { ApiResponse, PaginatedApiResponse } from '../types.api'

export const createListItem = async ({ listId, bookId }: { listId: number; bookId: number }): Promise<void> =>
  authenticatedApiInstance()
    .post(`/lists/${listId}/books`, { book: bookId })
    .then((res) => res.data.data)

export const deleteListItem = async ({ listId, bookId }: { listId: number; bookId: number }): Promise<void> =>
  authenticatedApiInstance()
    .delete(`/lists/${listId}/books/${bookId}`)
    .then((res) => res.data.data)

export const getList = async ({
  listId,
  page = 0,
  perPage = 20
}: {
  listId: number
  page?: number
  perPage?: number
}): Promise<ApiResponse<UserListInterface>> =>
  authenticatedApiInstance()
    .get(`/lists/v2/${listId}?page=${page}&perPage=${perPage}`)
    .then((res) => res.data.data)

export const getLists = async (): Promise<PaginatedApiResponse<UserListInterface>> =>
  authenticatedApiInstance()
    .get('/lists')
    .then((res) => res.data.data)

export const createNewList = async ({ title, makePublic }: { title: string; makePublic: boolean }): Promise<void> =>
  authenticatedApiInstance()
    .post('/lists', { title, private: !makePublic })
    .then((res) => res.data.data)

export const removeList = async ({ listId }: { listId: number }): Promise<void> =>
  authenticatedApiInstance()
    .delete(`/lists/${listId}`)
    .then((res) => res.data.data)
