import axios from 'axios'
import { getApiTenant, getAuthToken } from '../utils/api'
import { getConfigVariable } from '../utils/config'

const HERMES_API_BASE_URL = getConfigVariable('HERMES_API_URL')

const getApiVersion = (version?: string) => {
  const DEFAULT_VERSION = 'v1'
  const versions = new Set(['v1'])

  if (!version) return DEFAULT_VERSION

  return versions.has(version) ? version : DEFAULT_VERSION
}

export const authenticatedApiInstance = (version?: string) =>
  axios.create({
    baseURL: `${HERMES_API_BASE_URL}/${getApiVersion(version)}/${getApiTenant()}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getAuthToken()}`
    }
  })

export const publicApiInstance = (version?: string) =>
  axios.create({
    baseURL: `${HERMES_API_BASE_URL}/${getApiVersion(version)}/${getApiTenant()}`,
    headers: {
      'Content-Type': 'application/json'
    }
  })
