import { useCallback, useState } from 'react'
import useSWR from 'swr'
import { createListItem, createNewList, removeList as deleteList, deleteListItem, getLists } from './api'
import { buildCacheKey } from '../../utils/cache'
import { getActiveUser } from '../../setActiveUser'

export const useYourLists = ({ page = 0, pageSize = 20 }: { page?: number; pageSize?: number } = {}) => {
  const [currentPage, setCurrentPage] = useState(page)

  const user = getActiveUser()

  const getListsArgs = buildCacheKey('useYourLists', { page: currentPage, perPage: pageSize, userUuid: user?.id })
  const { data: lists, isLoading, error, mutate: dispatchListsUpdate } = useSWR(getListsArgs, getLists)

  const addItem = useCallback(
    async (listId: number, bookId: number) => {
      if (!listId) return

      await dispatchListsUpdate(
        async () => {
          await createListItem({ listId, bookId })
          return lists
        },
        { rollbackOnError: true }
      )
    },
    [dispatchListsUpdate, lists]
  )

  const removeItem = useCallback(
    async (listId: number, bookId: number) => {
      if (!listId) return

      await dispatchListsUpdate(
        async () => {
          await deleteListItem({ listId, bookId })
          return lists
        },
        { rollbackOnError: true }
      )
    },
    [dispatchListsUpdate, lists]
  )

  const createList = useCallback(
    async (title: string, makePublic: boolean) => {
      if (!title) return

      await dispatchListsUpdate(
        async () => {
          await createNewList({ title, makePublic })
          return lists
        },
        { rollbackOnError: true }
      )
    },
    [dispatchListsUpdate, lists]
  )

  const removeList = useCallback(
    async (listId: number) => {
      if (!listId) return

      await dispatchListsUpdate(
        async () => {
          await deleteList({ listId })
          return lists
        },
        { rollbackOnError: true }
      )
    },
    [dispatchListsUpdate, lists]
  )

  return {
    data: lists?.array,
    pagination: lists?.pagination,
    isLoading,
    nextPage: () => setCurrentPage(currentPage + 1),
    error,
    addItem,
    removeItem,
    createList,
    removeList,
    hasItem: (listId: number, bookId: number) =>
      lists?.array.find((list) => list.id === listId)?.books.some((book) => book.id === bookId),
    refresh: () => dispatchListsUpdate()
  }
}
