import type { ElasticBook, UserListInterface } from '@bookis/legacy-types'
import type { SWRInfiniteResponse } from 'swr/infinite'
import useSWRInfinite from 'swr/infinite'

import { useCallback, useMemo } from 'react'
import { getActiveUser } from '../../setActiveUser'
import { buildCacheKey } from '../../utils/cache'
import { createListItem, deleteListItem, getList } from './api'
import { useYourLists } from './use-your-lists'

// TODO: Should be refactored to include the full book object, not just the id
const addItemToLastPage = (pages: UserListInterface[], bookId: number) =>
  pages?.map((page, index) =>
    index === pages.length - 1 ? { ...page, books: [...page.books, { id: bookId } as ElasticBook] } : page
  ) ?? []

// TODO: Should be refactored to include the full book object, not just the id
const removeItemFromLastPage = (pages: UserListInterface[], bookId: number) =>
  pages?.map((page, index) =>
    index === pages.length - 1
      ? { ...page, books: page.books.filter((book: { id: number }) => book.id !== bookId) }
      : page
  ) ?? []

export const useYourList = ({ listId, perPage = 20 }: { listId: number | null; perPage?: number }) => {
  const getKey = (pageIndex: number, previousPageData: Awaited<ReturnType<typeof getList>>[]) => {
    if (!listId || (pageIndex === 0 && previousPageData && previousPageData.length)) return null
    const user = getActiveUser()

    return {
      key: buildCacheKey('useYourList', { listId, userUuid: user?.id }, { perPage }),
      params: {
        listId,
        page: pageIndex,
        perPage
      },
      pageIndex
    }
  }

  const {
    data: pages,
    isLoading,
    mutate: dispatchListUpdate,
    size: currentPage,
    setSize: setPage
  } = useSWRInfinite(getKey, ({ pageIndex, params }) => getList({ listId: params.listId, page: pageIndex, perPage }), {
    revalidateOnFocus: false
  })

  const { refresh: refreshListsCache } = useYourLists()

  const addItem = useCallback(
    async (book: { id: number } | number) => {
      if (!listId || !pages) return

      const bookId = typeof book === 'object' ? book.id : book
      const optimisticData = addItemToLastPage(pages, bookId)

      await dispatchListUpdate(
        async () => {
          await createListItem({ listId, bookId })
          return optimisticData
        },
        { optimisticData, rollbackOnError: true }
      )
      refreshListsCache()
    },
    [dispatchListUpdate, pages, refreshListsCache, listId]
  )

  const removeItem = useCallback(
    async (book: { id: number } | number) => {
      if (!listId || !pages) return

      const bookId = typeof book === 'object' ? book.id : book
      const optimisticData = removeItemFromLastPage(pages, bookId)

      await dispatchListUpdate(
        async () => {
          await deleteListItem({ listId, bookId })
          return optimisticData
        },
        { optimisticData, rollbackOnError: true }
      )
      refreshListsCache()
    },
    [dispatchListUpdate, pages, refreshListsCache, listId]
  )

  const results = pages?.flatMap((page) => page.books) ?? []

  const hasMore = results.length === currentPage * perPage

  const listItemIds = useMemo(() => results.flatMap((book) => book.id) ?? [], [results])

  const nextPage = useCallback(() => {
    if (hasMore) {
      setPage(currentPage + 1)
    }
  }, [hasMore, setPage, currentPage])

  return {
    data: results,
    isLoading,
    nextPage,
    addItem,
    removeItem,
    hasItem: (bookId: number) => listItemIds.includes(bookId),
    refresh: dispatchListUpdate as SWRInfiniteResponse<UserListInterface>['mutate'],
    listDetails: pages?.[0]
  }
}
