import { ShippingOptionDto } from '@hermes/domains'
import { publicApiInstance } from '../client'
import { ApiResponse } from '../types.api'

export const getShippingOptions = async (): Promise<ApiResponse<ShippingOptionDto[]>> =>
  publicApiInstance()
    .get<{ data: ShippingOptionDto[] }>('/shipping-options')
    .then((res) => res.data.data)

export const getShippingOption = async (id: string): Promise<ApiResponse<ShippingOptionDto>> =>
  publicApiInstance()
    .get<{ data: ShippingOptionDto }>(`/shipping-options/${id}`)
    .then((res) => res.data.data)
