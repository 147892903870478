import type { ElasticBook } from '@bookis/legacy-types'
import { authenticatedApiInstance, publicApiInstance } from '../client'

export const getWaitingListItems = async (): Promise<
  {
    id: number
    book: ElasticBook
  }[]
> =>
  authenticatedApiInstance()
    .get('/waitinglists')
    .then((res) => res.data.data)

export const getWaitingListStatusByBookId = async (bookId: number): Promise<boolean> =>
  authenticatedApiInstance()
    .get(`/waitinglists/isWaiting/${bookId}`)
    .then((res) => res.data.data.waitingNotification)

export const addToWaitingList = async (bookId: number): Promise<void> =>
  authenticatedApiInstance().post(`/waitinglists`, {
    book: bookId,
    availableNew: false,
    availableUsed: true
  })

export const removeFromWaitingList = async (bookId: number, userUuid?: string | number): Promise<void> =>
  publicApiInstance().delete(`/waitinglists/${bookId}?userUuid=${userUuid}`)
