type SearchParams = Record<string, string | number | boolean | (string | number)[] | null | undefined>

export const buildSearchParams = <T extends SearchParams>(params: T): Record<string, string> => {
  const filteredParams = Object.entries(params)
    .filter(([_, value]) => value !== undefined && value !== null)
    .reduce<Record<string, string>>(
      (acc, [key, value]) => ({
        ...acc,
        [key]: Array.isArray(value) ? value.join(',') : String(value)
      }),
      {}
    )

  return filteredParams
}
