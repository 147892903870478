import { useCallback } from 'react'
import useSWR from 'swr'
import { getActiveUser } from '../../setActiveUser'
import { buildCacheKey } from '../../utils/cache'
import { addToWaitingList, getWaitingListItems, removeFromWaitingList } from './api'

export const useYourWaitingList = () => {
  const user = getActiveUser()

  const cacheKey = buildCacheKey('use-waiting-list', { userUuid: user?.id })
  const { data, isLoading, mutate: dispatchWaitingListUpdate } = useSWR(cacheKey, getWaitingListItems)

  const addItem = useCallback(
    async (bookId: number) => {
      await dispatchWaitingListUpdate(
        async () => {
          await addToWaitingList(bookId)
          return data
        },
        { rollbackOnError: true }
      )
    },
    [dispatchWaitingListUpdate]
  )

  const removeItem = useCallback(
    async (bookId: number) => {
      await dispatchWaitingListUpdate(
        async () => {
          await removeFromWaitingList(bookId, getActiveUser()?.id)
          return data
        },
        { rollbackOnError: true }
      )
    },
    [dispatchWaitingListUpdate]
  )

  const hasItem = (bookId: number) =>
    (Array.isArray(data) && data.some((item) => item && item.book && item.book.id === bookId)) || false

  return { data, isLoading, hasItem, addItem, removeItem }
}
