import useSWR from 'swr'
import { getShippingOptions } from './api'
import { buildCacheKey } from '../../utils/cache'

export const useShippingOptions = () => {
  const cacheKey = buildCacheKey('shipping-options')
  const { data, isLoading, error } = useSWR(cacheKey, () => getShippingOptions())

  return { data, isLoading, error }
}
