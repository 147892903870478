import { useCallback, useMemo } from 'react'
import useSWRInfinite from 'swr/infinite'
import { buildCacheKey } from '../../utils/cache'
import { getListings, ListingCondition, ListingStatus, removeListingByUuid, updateListingByUuid } from './api'
import { getActiveUser } from '../../setActiveUser'

export const useYourListings = ({
  searchQuery,
  listingStatus,
  excludeListingIds,
  size = 20
}: {
  searchQuery?: string
  excludeListingIds?: number[]
  size?: number
  listingStatus?: (typeof ListingStatus)[keyof typeof ListingStatus][]
} = {}) => {
  const getCacheKey = (pageIndex: number, previousPageData: any) => {
    if (pageIndex > 0 && previousPageData && previousPageData.data.length === 0) return null
    const user = getActiveUser()

    return {
      key: 'useYourListings',
      context: buildCacheKey(
        'useYourListings',
        { userUuid: user?.id },
        { size, searchQuery, excludeListingIds, listingStatus }
      ),
      pageIndex
    }
  }

  const {
    data,
    isLoading,
    setSize,
    size: currentPage,
    mutate: dispatchListingsUpdate,
    error
  } = useSWRInfinite(
    getCacheKey,
    ({ pageIndex }) => getListings({ page: pageIndex + 1, size, searchQuery, excludeListingIds, listingStatus }),
    {
      revalidateOnFocus: false,
      errorRetryCount: 0,
      keepPreviousData: true
    }
  )

  const updateListing = useCallback(
    async (
      listingUuid: string,
      body: { price?: number; condition?: (typeof ListingCondition)[keyof typeof ListingCondition]; comment?: string }
    ) => {
      if (!listingUuid) return

      await dispatchListingsUpdate(
        async () => {
          await updateListingByUuid({ listingUuid, body })
          return data
        },
        { rollbackOnError: true }
      )
    },
    [dispatchListingsUpdate, data]
  )

  const removeListing = useCallback(
    async (listingUuid: string) => {
      const optimisticData = data?.map((page) => ({
        ...page,
        data: page.data.filter((listing) => listing.uuid !== listingUuid)
      }))

      await dispatchListingsUpdate(
        async () => {
          await removeListingByUuid({ listingUuid })
          return optimisticData
        },
        {
          optimisticData,
          rollbackOnError: true
        }
      )
    },
    [dispatchListingsUpdate]
  )

  const results = useMemo(() => data?.map((page) => page.data).flat(), [data])

  const pagination = useMemo(() => data?.findLast((pg) => pg.pagination)?.pagination, [data])

  const hasMore = pagination?.continue

  return {
    data: results,
    isLoading: isLoading || (currentPage > 0 && data && typeof data[currentPage - 1] === 'undefined') || false,
    setSize,
    nextPage: () => hasMore && setSize(currentPage + 1),
    page: currentPage,
    updateListing,
    removeListing,
    refresh: () => dispatchListingsUpdate(undefined, { revalidate: true }),
    error
  }
}
