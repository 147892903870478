import { publicApiInstance } from '../client'

export const validateAddressByShippingOption = (
  params: {
    fulfillmentProviderId: string
  },
  body: {
    optionId: number
    addressId: number
    partyType: 'sender' | 'recipient'
  }
) =>
  publicApiInstance()
    .post(`/fulfillment/${params.fulfillmentProviderId}/validate/address`, body)
    .then((res) => res.data.data)
