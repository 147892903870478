import {
  ListingCondition as ListingConditionType,
  ListingDeliveryMethod as ListingDeliveryMethodType,
  ListingDto,
  ListingStatus as ListingStatusType
} from '@hermes/domains'
import { buildSearchParams } from '../../utils/url'
import { authenticatedApiInstance, publicApiInstance } from '../client'
import { PaginatedApiResponse } from '../types.api'

export const ListingStatus: Record<string, ListingStatusType> = {
  SOLD: 'sold',
  REQUESTED: 'requested',
  AVAILABLE: 'available',
  DELETED: 'deleted',
  USER_DEACTIVATED: 'userDeactivated'
} as const

export const ListingCondition: Record<string, ListingConditionType> = {
  LIKE_NEW: 'like new',
  VERY_GOOD: 'very good',
  GOOD: 'good',
  ACCEPTABLE: 'acceptable',
  REFURBISHED: 'refurbished'
} as const

export const ListingDeliveryMethod: Record<string, ListingDeliveryMethodType> = {
  SHIPPING: 'shipping',
  MEETUP: 'meetup',
  BOTH: 'both'
} as const

export const getListings = async (query: {
  page: number
  size: number
  searchQuery?: string
  excludeListingIds?: number[]
  listingStatus?: ListingStatusType[]
}): Promise<PaginatedApiResponse<ListingDto>> =>
  authenticatedApiInstance()
    .get(`/listings`, { params: buildSearchParams(query) })
    .then((res) => res.data)

export const getListingByUuid = async ({ listingUuid }: { listingUuid: string }): Promise<ListingDto> =>
  authenticatedApiInstance()
    .get(`/listings/${listingUuid}`)
    .then((res) => res.data)

export const updateListingByUuid = async ({
  listingUuid,
  body
}: {
  listingUuid: string
  body: { price?: number; condition?: (typeof ListingCondition)[keyof typeof ListingCondition]; comment?: string }
}): Promise<ListingDto> =>
  authenticatedApiInstance()
    .put(`/listings/${listingUuid}`, body)
    .then((res) => res.data)

export const removeListingByUuid = async ({ listingUuid }: { listingUuid: string }): Promise<ListingDto> =>
  authenticatedApiInstance()
    .delete(`/listings/${listingUuid}`)
    .then((res) => res.data)

export const getRecommendedListingsToBookByBookUuid = async (
  params: { bookUuid: string },
  query: { page: number; size: number; deliveryMethods?: ('shipping' | 'meetup' | 'both')[] }
): Promise<PaginatedApiResponse<ListingDto>> =>
  publicApiInstance()
    .get(`/listings/book/${params.bookUuid}/recommended`, {
      params: buildSearchParams(query)
    })
    .then((res) => res.data)

export const getRecommendedListingsToUserByBookUuid = async (
  params: { sellerUuid: string; bookUuid: string },
  query: {
    page: number
    size: number
    deliveryMethods?: ('shipping' | 'meetup' | 'both')[]
    searchQuery?: string
    excludeListingIds?: number[]
  }
): Promise<PaginatedApiResponse<ListingDto>> =>
  publicApiInstance()
    .get(`/listings/user/${params.sellerUuid}/book/${params.bookUuid}/recommended`, {
      params: buildSearchParams(query)
    })
    .then((res) => res.data)
