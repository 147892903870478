'use client'
import { setActiveTenant, setActiveUser } from '@hermes/api-sdk'
import { UserInterface } from '@hermes/types'
import { useUserToken } from '@hooks/useUserToken'
import { fetchMe, getUserIntercomHash } from '@lib/services/user'
import getRegionFromLocale from '@lib/utils/getRegionFromLocale'
import { CookieValueTypes } from 'cookies-next'
import React from 'react'
import useSWR from 'swr'

type UserContextProps = {
  user: (UserInterface & { intercomUserHash: string }) | null
  isLoading: boolean
  revalidate: () => Promise<void>
  error: any
}

const UserContext = React.createContext<UserContextProps | null>(null)

const useUser = (): UserContextProps => {
  const context = React.useContext<UserContextProps | null>(UserContext)
  if (context === null) {
    throw new Error('useUser must be used within a UserProvider')
  }
  return context
}

const fetchUserData = async (token: CookieValueTypes, region: string) => {
  if (!token || !region) {
    return null
  }

  const userPromises = [fetchMe({ token, country: region }), getUserIntercomHash({ token, country: region })]
  const [user, intercomHash] = await Promise.all(userPromises)

  return {
    ...user.data as any,
    intercomUserHash: intercomHash.data as string
  }
}

const UserProvider: React.FC<{ locale: string; children: React.ReactNode }> = ({ locale, children }) => {
  const { token, deleteToken } = useUserToken()
  const region = getRegionFromLocale(locale)


  const {
    data: user,
    error,
    mutate,
    isLoading
  } = useSWR(`${token?.toString()}-${region}`, () => fetchUserData(token!, region), {
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    onError: (error) => {
      console.error(error)
      deleteToken()
      // Optionally, inform the user of the error in a user-friendly manner
    }
  })

  if (user && token) {
    setActiveUser({ token: token.toString(), id: user.id })
  }
  setActiveTenant(region)

  const revalidate = () => mutate()

  return <UserContext.Provider value={{ user, revalidate, isLoading, error }}>{children}</UserContext.Provider>
}

export { UserProvider, useUser }
