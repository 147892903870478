// TODO: Improve this to be more flexible
const configVariables = {
  NODE_ENV: process.env.NODE_ENV,
  HERMES_API_URL:
    process.env.EXPO_PUBLIC_HERMES_API_URL || process.env.NEXT_PUBLIC_HERMES_API_URL || process.env.HERMES_API_URL,
  LEGACY_API_URL:
    process.env.EXPO_PUBLIC_LEGACY_API_URL || process.env.NEXT_PUBLIC_LEGACY_API_URL || process.env.LEGACY_API_URL,
  SEARCH_API_URL:
    process.env.EXPO_PUBLIC_SEARCH_API_URL || process.env.NEXT_PUBLIC_SEARCH_API_URL || process.env.SEARCH_API_URL,
  SEARCH_API_KEY:
    process.env.EXPO_PUBLIC_SEARCH_API_KEY || process.env.NEXT_PUBLIC_SEARCH_API_KEY || process.env.SEARCH_API_KEY
}

export const getConfigVariable = (variable: keyof typeof configVariables): string => {
  const value = configVariables[variable]
  if (!value) {
    throw new Error(`Environment variable ${variable} is not set`)
  }
  return value
}
