import useSWR from 'swr'
import { useCallback } from 'react'
import { createBookRating, getBookRatings, updateBookRating } from './api'
import { buildCacheKey } from '../../utils/cache'

export const useBookRatings = ({ bookId, userId }: { bookId: number; userId?: number | null }) => {
  const cacheKey = buildCacheKey('useBookRatings', { bookId })
  const { data: ratings, isLoading, mutate: dispatchRatingsUpdate } = useSWR(cacheKey, () => getBookRatings({ bookId }))

  const userRating = ratings?.find((rating) => rating.user.id === userId)

  const upsertRating = useCallback(
    async ({ ratingScore }: { ratingScore: number }) => {
      await dispatchRatingsUpdate(
        async () => {
          if (userRating) {
            await updateBookRating({ bookId, ratingId: userRating.id, ratingScore })
          } else {
            await createBookRating({ bookId, ratingScore })
          }
          return ratings
        },
        { rollbackOnError: true }
      )
    },
    [dispatchRatingsUpdate, userRating]
  )

  return {
    data: ratings,
    isLoading,
    upsertRating
  }
}
