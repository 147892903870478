import useSWR from 'swr'
import { useMemo } from 'react'
import { buildCacheKey } from '../../utils/cache'
import { getMoreLikeThisBooks } from './api'

export const useBookSuggestions = ({
  bookId,
  size,
  page,
  isAvailable,
  excludeBookIds
}: {
  bookId: number
  size?: number
  page?: number
  isAvailable?: boolean
  excludeBookIds?: number[]
}) => {
  const cacheKey = buildCacheKey('useBookSuggestions', { bookId }, { size, page, isAvailable })

  const { data, isLoading } = useSWR(cacheKey, () => getMoreLikeThisBooks({ bookId }, { size, page, isAvailable }))

  const filteredData = useMemo(() => data?.filter((book) => !excludeBookIds?.includes(book.id)), [data, excludeBookIds])

  return {
    data: filteredData,
    isLoading
  }
}
