import type { ReviewInterface } from '@bookis/legacy-types'
import { authenticatedApiInstance, publicApiInstance } from '../client'
import { PaginatedApiResponse } from '../types.api'

export const getBookReviews = async ({
  bookId,
  page = 0,
  perPage = 10
}: {
  bookId: number
  page?: number
  perPage?: number
}): Promise<PaginatedApiResponse<ReviewInterface>> =>
  publicApiInstance()
    .get(`/book/${bookId}/reviews?page=${page}&perPage=${perPage}`)
    .then((res) => res.data.data)

export const createBookReview = async ({ bookId, review }: { bookId: number; review: string }): Promise<void> =>
  authenticatedApiInstance().post(`/book/${bookId}/reviews`, { review })

export const updateBookReview = async ({
  bookId,
  reviewId,
  review
}: {
  bookId: number
  reviewId: number
  review: string
}) => authenticatedApiInstance().put(`/book/${bookId}/reviews/${reviewId}`, { review })

export const deleteBookReview = async ({ bookId, reviewId }: { bookId: number; reviewId: number }): Promise<void> =>
  authenticatedApiInstance().delete(`/book/${bookId}/reviews/${reviewId}`)

export const likeBookReview = async ({ bookId, reviewId }: { bookId: number; reviewId: number }): Promise<void> =>
  authenticatedApiInstance().post(`/book/${bookId}/reviews/${reviewId}/likes`)
