import { BookDto } from '@hermes/domains'
import { publicApiInstance } from '../client'
import { buildSearchParams } from '../../utils/url'

export const getBookById = async ({ bookId }: { bookId: number | string }): Promise<BookDto> =>
  publicApiInstance()
    .get(`/books/${bookId}`)
    .then((res) => res.data.data)

export const searchBooks = async (query: {
  isbn13?: string[]
  authorIds?: number[]
  isAvailable?: boolean
}): Promise<BookDto[]> =>
  publicApiInstance()
    .get(`/books/search`, { params: buildSearchParams(query) })
    .then((res) => res.data.data)

export const getMoreLikeThisBooks = async (
  params: { bookId: number },
  query: { size?: number; page?: number; isAvailable?: boolean }
): Promise<BookDto[]> =>
  publicApiInstance()
    .get(`/books/${params.bookId}/more-like-this`, { params: buildSearchParams(query) })
    .then((res) => res.data.data)
