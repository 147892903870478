export enum Region {
  SWEDEN = 'se',
  NORWAY = 'no'
}

export const getValidatedRegion = (region: string): Region => {
  const regionMap: Record<string, Region> = {
    no: Region.NORWAY,
    se: Region.SWEDEN
  }

  const FALLBACK_REGION = Region.NORWAY

  return regionMap[region] || FALLBACK_REGION
}
