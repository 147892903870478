import type { BookRating } from '@bookis/legacy-types'
import { authenticatedApiInstance, publicApiInstance } from '../client'
import { ApiResponse } from '../types.api'

export const getBookRatings = async ({ bookId }: { bookId: number }): Promise<ApiResponse<BookRating[]>> =>
  publicApiInstance()
    .get(`/book/${bookId}/ratings`)
    .then((res) => res.data.data)

export const createBookRating = async ({ bookId, ratingScore }: { bookId: number; ratingScore: number }) =>
  authenticatedApiInstance().post(`/book/${bookId}/ratings`, { rate: ratingScore })

export const updateBookRating = async ({
  bookId,
  ratingId,
  ratingScore
}: {
  bookId: number
  ratingId: number
  ratingScore: number
}) => authenticatedApiInstance().patch(`/book/${bookId}/ratings/${ratingId}`, { rate: ratingScore })
