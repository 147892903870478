import useSWRInfinite from 'swr/infinite'
import { buildCacheKey } from '../../utils/cache'
import { SortByAttribute } from '../constants.api'
import { getHits, MeiliSearchParams } from './api'

export type FacetAttribute =
  | 'authors.primaryRoles.fullName'
  | 'books.availability.meetup.available'
  | 'books.availability.shipping.available'
  | 'books.editionNumber'
  | 'books.format'
  | 'books.languages'
  | 'books.publisher'
  | 'books.seriesTitle'
  | 'books.year'
  | 'genres.code'
  | 'languages'
  | 'ratingAvg'
  | 'ratingVotes'
  | 'reviewCount'
  | 'seriesTitle'

export type FacetItem = {
  attribute: FacetAttribute
  operator?: 'or' | 'and'
  transformItems?: (items: FacetListItem[]) => FacetListItem[]
}

export type SortingOption = {
  value: SortByAttribute
}

export type Refinement = {
  value: string
  attribute: FacetAttribute
}

export type FacetListItem = Refinement & {
  isRefined: boolean
  count: number
  label: string
}

export type SearchProps = {
  query: string
  perPage: number
  semanticEnabled: boolean
  filterAttributes: string[] | null
  activeRefinements: Set<Refinement>
  currentSortOption: SortingOption
}

const ATTRIBUTES_TO_SEARCH_ON = [
  'books.title',
  'books.subtitle',
  'books.description',
  'authors.primaryRoles.fullName',
  'books.fullIsbn',
  'books.shortIsbn'
]

const EMBEDDER_NAME = 'embedder'

const formatFacetKeyForMeiliSearch = (attribute: string, value: string) => `${attribute} = "${value}"`

export const useBookSearchInfinite = ({
  query,
  perPage,
  semanticEnabled,
  filterAttributes,
  activeRefinements,
  currentSortOption
}: SearchProps) => {
  const searchIsEnabled =
    query.length > 0 || activeRefinements.size > 0 || currentSortOption.value !== SortByAttribute.RELEVANCE
  const cacheKey = (pageIndex: number) =>
    buildCacheKey<MeiliSearchParams>(
      'useBookSearchInfinite',
      {
        page: pageIndex + 1,
        hitsPerPage: perPage,
        q: query
      },
      {
        hybrid: semanticEnabled
          ? {
              semanticRatio: 1,
              embedder: EMBEDDER_NAME
            }
          : undefined,
        attributesToSearchOn: ATTRIBUTES_TO_SEARCH_ON,
        facets: filterAttributes,
        filter: Array.from(activeRefinements).map((refinement) =>
          formatFacetKeyForMeiliSearch(refinement.attribute, refinement.value)
        ),
        sort: currentSortOption?.value ? [currentSortOption.value] : undefined
      },
      true
    )

  const {
    data: response,
    size: swrSize,
    setSize: setSwrSize,
    isLoading
  } = useSWRInfinite(
    searchIsEnabled ? (pageIndex) => cacheKey(pageIndex) : () => null,
    (params) =>
      getHits({
        params
      }),
    {
      revalidateFirstPage: false,
      keepPreviousData: true
    }
  )

  const hits = searchIsEnabled ? response?.map((res) => res.hits).flat() || [] : []
  const mostRecentResponseObject = response?.at(-1)
  const hasMore = (mostRecentResponseObject?.totalPages || 0) > (mostRecentResponseObject?.page || 0)

  const nextPage = () => {
    if (hasMore) setSwrSize(swrSize + 1)
  }

  return {
    hits,
    isLoading: isLoading || (swrSize > 0 && response && typeof response[swrSize - 1] === 'undefined') || false,
    nextPage,
    hasMore,
    currentPage: swrSize,
    facetDistribution: mostRecentResponseObject?.facetDistribution || {},
    pagination: {
      totalHits: mostRecentResponseObject?.totalHits || 0
    }
  }
}
