import type { RequestDetailsInterface } from '@bookis/legacy-types'
import { authenticatedApiInstance } from '../client'

export const sendMeetupRequest = async ({
  userId,
  price,
  saleId,
  sellerId,
  message
}: {
  userId: number
  price: number
  saleId: number
  sellerId: number
  message: string
}): Promise<{
  data: RequestDetailsInterface
  code?: number
  message?: string
}> =>
  authenticatedApiInstance()
    .post('/requests', {
      userId,
      delivery: 'meetup',
      price,
      saleId,
      sellerId,
      message
    })
    .then((res) => res.data)
