import type { ElasticBook } from '@bookis/legacy-types'
import { authenticatedApiInstance } from '../client'
import { ApiResponse } from '../types.api'

const transformListType = (type: 'haveRead' | 'reading' | 'notReadYet') => {
  if (type === 'haveRead') return 'have read'
  if (type === 'reading') return 'reading'
  if (type === 'notReadYet') return 'not read yet'
  throw new Error('Invalid list type')
}

export const getMyBookLists = async (): Promise<
  ApiResponse<{
    haveRead: {
      books: Array<ElasticBook>
      total: number
      lastHaveReadBook: unknown
    }
    reading: {
      books: Array<ElasticBook>
      total: number
      lastReadingBook: unknown
    }
    notReadYet: {
      books: Array<ElasticBook>
      total: number
      lastNotReadYetBook: unknown
    }
  }>
> =>
  authenticatedApiInstance()
    .get('/mybooks')
    .then((res) => res.data.data)

export const createMyBookListItem = async ({
  type,
  bookId
}: {
  type: 'haveRead' | 'reading' | 'notReadYet'
  bookId: number
}) => authenticatedApiInstance().post(`/mybooks/${bookId}`, { type: transformListType(type) }, { params: { bookId } })

export const deleteMyBookListItem = async ({ bookId }: { bookId: number }) =>
  authenticatedApiInstance().delete(`/mybooks/${bookId}`)
